<template>
    <div class="index">

        <div class="top">
            <img src="../../assets/image/new_return.png" alt="" class="go-back" @click="handleGoBack">
            <router-link to="/manage-stage" tag="li">工作台</router-link>
            <!-- <router-link to="/manage-space" tag="li">空间管理</router-link>
            <router-link to="/manage-building" tag="li">楼书管理</router-link> -->
            <router-link to="/manage-team" tag="li" v-if="isPower">团队管理</router-link>
            <div class="user">
                <img :src="QINIU_CDN_HOST + head" alt="" class="head">
                <img src="@/assets/image/out_icon.png" alt="" class="out" @click='handleOut'>
            </div>
            <el-dialog title="提示" :visible.sync="outVisible" width="30%" :before-close="handleClose">
                <span>确定退出？</span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="outVisible = false">取 消</el-button>
                    <el-button type="primary" @click="goOut">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="content">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'manage-index',
        data() {
            return {
                head: sessionStorage.getItem('headImg'),
                outVisible: false,
                isPower:false
            }
        },
        methods: {
            handleGoBack() {
                this.$router.push({
                    path: "/",
                    query: {
                        panorama_id: sessionStorage.getItem('panorama_id'),
                        user_id: sessionStorage.getItem('user_id'),
                        building_id: sessionStorage.getItem('building_id')
                    }
                })
            },
            handleOut() {
                this.outVisible = true
            },
            goOut() {
                this.$axios({
                    method: 'post',
                    url: 'api/v5/panorama/loginOut',
                    headers: {
                        // "panorama-api-token": '8bd7bc3d18c055c3eefbc311631b705e'
                        "panorama-api-token": sessionStorage.getItem('token')
                    }
                }).then(res => {
                    if (res.status == '200') {
                        sessionStorage.removeItem('token')
                        this.handleGoBack()
                    }
                })
            },
            handleClose() {
                this.outVisible = false
            }
        },
        created() {
            this.isPower = sessionStorage.getItem('power')
        },
    }
</script>

<style lang="scss" scoped>
    .index {
        -moz-user-select: text;
        /* Firefox */
        -webkit-user-select: text;
        /* WebKit */
        -ms-user-select: text;
        /* IE */
        -khtml-user-select: text;
        /* KHTML */
        -o-user-select: text;
        /* Opera */
        user-select: text;

    }

    .top {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 120px;
        background: #000;

        .user {
            position: absolute;
            display: flex;
            align-items: center;
            top: 50%;
            right: 140px;
            transform: translateY(-50%);

            .head,
            .out {
                width: 40px;
                height: 40px;
                cursor: pointer;
            }

            .head {
                margin-right: 30px;
                border-radius: 20px;
            }
        }
    }

    .go-back {
        position: absolute;
        width: auto;
        height: 30px;
        top: calc(50% - 15px);
        left: 40px;
        cursor: pointer;
        z-index: 2;
    }

    li {
        margin: 0 40px;
        padding: 10px 0;
        list-style: none;
        font-size: 20px;
        color: #fff;
        box-sizing: border-box;

        &:first-of-type {
            margin-left: 180px;
        }

    }

    .router-link-active {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: #fff;
        }

        /* border-bottom:2px solid #fff; */
    }

    .content {
        padding: 10px 40px;
        width: 100%;
        height: calc(100vh - 120px);
        background: #fff;
    }
</style>